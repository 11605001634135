import React from 'react';

const About = () => {
  return (
    <>
    <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
      <title>About Upaman - Discovering the World of Technology, Facts, and Knowledge</title>
      <meta name="description" content="Learn about Upaman - A diverse platform offering insights into technology advancements, intriguing facts, and enriching knowledge. Uncover fascinating content curated for curious minds."></meta>
        <h1 className="text-3xl font-extrabold text-gray-900 mb-8">About Us</h1>
        <div className="bg-white rounded-lg shadow px-6 py-8">
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            At Upaman, we are passionate about delivering reliable and up-to-date technology information to empower individuals and businesses. Our mission is to simplify complex technology concepts and make them accessible to everyone.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            What sets us apart is our team of dedicated technology enthusiasts who have a deep understanding of the ever-evolving tech landscape. We strive to provide comprehensive and insightful information across various technology domains, including software, hardware, artificial intelligence, cybersecurity, and more.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            Our goal is to be your trusted source for the latest tech trends, news, reviews, and tutorials. Whether you are a tech enthusiast, a professional in the industry, or someone looking to stay informed about technology, Upaman is here to cater to your needs.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            We value accuracy, objectivity, and integrity in our content creation process. Our team works tirelessly to research, curate, and verify the information we present to you. We believe that by delivering reliable and high-quality content, we can help you make informed decisions and stay ahead in the fast-paced world of technology.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed mb-6">
            At Upaman, we are committed to continuous improvement and innovation. We actively seek feedback from our users to enhance our content and user experience. Your satisfaction and trust are our top priorities.
          </p>
          <p className="text-lg text-gray-700 leading-relaxed">
            Join us on this exciting journey of exploration and discovery in the realm of technology. Let Upaman be your companion in navigating the ever-changing tech landscape and unlocking the potential that technology holds.
          </p>
        </div>
      </div>
      </div>
      <div className="bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
        <title>Contact Upaman - Connect with Us for Tech Updates and Entertaining Animal Facts</title>
        <meta name="description" content="Get in touch with Upaman - Reach out to us for any inquiries, suggestions, or collaborations. Stay connected to the world of technology and exciting animal facts."></meta>
          <h1 className="text-3xl font-extrabold text-gray-900 mb-8">Contact Us</h1>
          <div className="bg-white rounded-lg shadow px-6 py-8">
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              Have a question or need assistance? Feel free to reach out to us! Our team is here to help.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-6">
              For any inquiries, you can contact us at <a href="mailto:upamanllc@gmail.com" className="text-blue-500">upamanllc@gmail.com</a>. We'll get back to you as soon as possible.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed">
              We appreciate your feedback, suggestions, and ideas. Let's connect and explore the world of technology together!
            </p>
          </div>
        </div>
      </div>
      </>
    
  );
};

export default About;
