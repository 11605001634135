import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 py-4 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-center">
          © {new Date().getFullYear()} Upaman. All rights reserved.
        </p>
        <p className="text-center">
        © All images and content belong to their respective brands. Used under fair use or with permission.

        </p>
      </div>
    </footer>
  );
};

export default Footer;
