// src/components/BestGadgets2024.jsx

import React, { useState } from 'react';
import { FaMicrochip, FaMobileAlt, FaMemory, FaBatteryFull } from 'react-icons/fa';

const gadgetsData = [
  {
    id: 1,
    name: 'Samsung S24 Ultra',
    image: 'assets/samsung.jpg',
    review: 'The Samsung Galaxy S24 Ultra is a flagship smartphone that continues to push the boundaries of mobile technology. Equipped with an Exynos 2200 processor and 12GB of RAM, it delivers top-tier performance for demanding tasks and multitasking. The device features a stunning 6.8-inch QHD+ AMOLED display, offering vibrant colors and sharp details. One of the standout features is its advanced camera system, including a 200MP main sensor, a 50MP 5x telephoto lens, and a 12MP ultrawide lens, which together provide exceptional photography capabilities in various lighting conditions. The 5000mAh battery ensures long-lasting usage',
    features: [
      { icon: <FaMicrochip />, name: 'Processor', description: 'Snapdragon 888' },
      { icon: <FaMobileAlt />, name: 'Screen', description: 'AMOLED, 120Hz' },
      { icon: <FaMemory />, name: 'RAM', description: '8GB' },
      { icon: <FaBatteryFull />, name: 'Battery', description: '4500mAh' },
    ],
    reviewLink: '/gadgets/samsung-s24-ultra'
  },
  // {
  //   id: 2,
  //   name: 'Samsung S24 Ultra',
  //   image: 'assets/samsung.jpg',
  //   review: 'The Samsung Galaxy S24 Ultra is a flagship smartphone that continues to push the boundaries of mobile technology. Equipped with an Exynos 2200 processor and 12GB of RAM, it delivers top-tier performance for demanding tasks and multitasking. The device features a stunning 6.8-inch QHD+ AMOLED display, offering vibrant colors and sharp details. One of the standout features is its advanced camera system, including a 200MP main sensor, a 50MP 5x telephoto lens, and a 12MP ultrawide lens, which together provide exceptional photography capabilities in various lighting conditions. The 5000mAh battery ensures long-lasting usage',
  //   features: [
  //     { icon: <FaMicrochip />, name: 'Processor', description: 'Exynos 2100' },
  //     { icon: <FaMobileAlt />, name: 'Screen', description: 'LCD, 90Hz' },
  //     { icon: <FaMemory />, name: 'RAM', description: '6GB' },
  //     { icon: <FaBatteryFull />, name: 'Battery', description: '5000mAh' },
  //   ],
  //   reviewLink: '/gadgets/samsung-s24-ultra'
  // },
  // Add more gadgets here
];

const BestGadgets2024 = () => {
  const [hoveredFeature, setHoveredFeature] = useState({});

  const handleMouseEnter = (gadgetId, feature) => {
    setHoveredFeature({ gadgetId, feature });
  };

  const handleMouseLeave = () => {
    setHoveredFeature({});
  };

  const openReviewPage = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="p-4 bg-gray-100">
      <h1 className="text-3xl font-bold text-center mb-8">Best Gadgets of 2024: In-Depth Reviews and Comparisons</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {gadgetsData.map(gadget => (
          <div key={gadget.id} className="relative bg-white p-4 rounded shadow-md">
            <div className="grid grid-cols-4 grid-rows-4 gap-2">
              <div className="col-span-4 row-span-2">
                <img 
                  src={gadget.image} 
                  alt={gadget.name} 
                  className="h-48 w-50 object-cover rounded mb-4 cursor-pointer" 
                  onClick={() => openReviewPage(gadget.reviewLink)} 
                />
              </div>
              {gadget.features.map((feature, index) => (
                <div
                  key={index}
                  className="relative col-span-1 row-span-1 flex flex-col items-center justify-center"
                  onMouseEnter={() => handleMouseEnter(gadget.id, feature)}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="text-3xl">{feature.icon}</div>
                  <div className="text-sm mt-1">{feature.name}</div>
                  {hoveredFeature.gadgetId === gadget.id && hoveredFeature.feature === feature && (
                    <div className="absolute z-10 p-2 bg-black text-white text-xs rounded shadow-lg -top-10 w-40">
                      <div className="font-bold">{feature.name}</div>
                      <div>{feature.description}</div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-4 p-4 bg-gray-200 rounded shadow-inner">
              <h3 className="text-xl font-bold">Review</h3>
              <p>{gadget.review}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestGadgets2024;
