export const elephantFunFacts = [
    "Elephants are the largest land animals on Earth, with some African elephants weighing over 13,000 pounds!",
    "Did you know? Elephants can communicate with each other using low-frequency rumbles that can travel through the ground for miles.",
    "Elephants are herbivores and spend up to 16 hours a day eating! They consume hundreds of pounds of vegetation to fuel their massive bodies.",
    "Elephants have an incredible memory. They can remember familiar faces, locations of water sources, and even recognize humans they've encountered before.",
    "Baby elephants, known as calves, are born weighing around 200 pounds and can stand and walk shortly after birth.",
    "Elephants have a special way of keeping themselves cool in hot weather. They use their large ears as fans to help regulate their body temperature.",
    "Elephants are very social creatures. They form tight-knit family groups led by the oldest female, called the matriarch.",
    "Elephants are skilled swimmers and can use their trunks as snorkels when crossing deep water.",
    "Just like humans, elephants have a sense of self-awareness. They can recognize themselves in a mirror, which is a rare ability in the animal kingdom.",
    "Elephants are artists! They've been known to use their trunks to create paintings and drawings, showing off their artistic talents.",
  ];
  
  const lionFacts = [
    "Lions are social animals, living in prides led by a dominant male.",
    "A lion's roar can be heard up to five miles away, serving as a territorial warning and a way to communicate with pride members.",
    "Female lions are the primary hunters, relying on teamwork to take down large prey.",
    "Lions are the second-largest big cat species after tigers.",
    "They are native to Africa and some parts of Asia.",
    // Add more lion facts...
  ];
  
  const tigerFacts = [
    "Tigers are solitary creatures, marking their territories with scent markings.",
    "A tiger's stripes are unique, acting as camouflage in the tall grass of their habitats.",
    "They are powerful swimmers and can cross rivers to pursue prey.",
    "Tigers have retractable claws, helping them maintain sharpness for hunting.",
    "Sadly, several tiger species are critically endangered due to habitat loss and poaching.",
    // Add more tiger facts...
  ];
  
  const cheetahFacts = [
    "Cheetahs are the fastest land animals, capable of reaching speeds up to 70 miles per hour.",
    "They have non-retractable claws that provide better traction while running.",
    "Cheetahs have excellent vision to spot prey from long distances.",
    "Unlike other big cats, cheetahs cannot roar but instead chirp and purr.",
    "Their distinctive tear lines help reduce glare and improve their focus during hunts.",
    // Add more cheetah facts...
  ];
  
  const kangarooFacts = [
    "Kangaroos are marsupials, carrying their underdeveloped young in a pouch.",
    "They use their powerful hind legs for hopping and can leap impressive distances.",
    "Kangaroos are herbivores, feeding on grass and vegetation.",
    "Female kangaroos can delay the development of their embryos during tough times, known as embryonic diapause.",
    "Australia is the primary home to various kangaroo species.",
    // Add more kangaroo facts...
  ];
  
  const eagleFacts = [
    "Eagles have exceptional eyesight, enabling them to spot prey from high altitudes.",
    "They are skilled hunters, diving at incredible speeds to catch their meals.",
    "Eagles use their sharp talons to grasp and carry their prey.",
    "These birds of prey build large nests called eyries in high places for breeding.",
    "Eagles are revered as symbols of strength and freedom in many cultures.",
    // Add more eagle facts...
  ];
  
const dogsFacts = [
    'Dogs are known for their loyalty and strong bond with humans, making them popular pets.',
    'They have an exceptional sense of smell, which is used in various tasks like search and rescue.',
    'Dogs come in a wide range of breeds, each with distinct characteristics and appearances.',
    'They communicate using barks, whines, and body language to express their emotions.',
    'Dogs have been domesticated for thousands of years and have played significant roles in human history.'
]

  // Add the remaining animal facts constants (dolphin, shark, monkey, snake, dogs, giraffe)
  
  // Export all the animal facts constants
  export {
    lionFacts,
    tigerFacts,
    cheetahFacts,
    kangarooFacts,
    eagleFacts,
    dogsFacts
    // Add the other animal facts constants here
  };
  
