import React from 'react';
import { css } from '@emotion/react'; // Import from @emotion/react
import { ScaleLoader } from 'react-spinners';
import './Loading.css'; // Import CSS for styling

const Loading = () => {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <div className="loading-container">
      <ScaleLoader color="#007bff" css={override} size={150} />
    </div>
  );
};

export default Loading;
