import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header.component';
import About from './About.component';
// import Contact from './Contact.component';
import Footer from './Footer.component';
import AnimalInfo from './AnimalInfo.component';
import DRYPrinciple from './tutorials/dry.component';
import Overview from './tutorials/overview.component';
import BestGadgets2024 from './Gadgets.component';
import SamsungS24UltraReview from './DetailedReview.component';
import Blog from './Blog.component';
import TechNews from './TechNewsPage.js';


function RouterComponent() {
  return (
    <div>

      <Router>
        <Header />
        <Routes>
          <Route path="/" exact element={<TechNews/>} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/contact" element={<Contact />} /> */}
          <Route path='/animalinfo' element={<AnimalInfo />} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/gadgets" element={<BestGadgets2024/>} />
          <Route path="/gadgets/samsung-s24-ultra" element={<SamsungS24UltraReview />} />
          <Route exact path='/tutorial' element={<Overview />} />
          <Route path="/tutorial/dry-principle" element={<DRYPrinciple />} />
          
        </Routes>
        <Footer />
      </Router>


    </div>
  );
}

export default RouterComponent;
