import React from 'react';
import TutorialsNavbar from './TutorialsNavbar';

const DRYPrinciple = () => {
  return (
    <>
    <TutorialsNavbar/>
    <div className="bg-gray-100 min-h-screen py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Tutorial: Understanding DRY Principle in Software Development</h2>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Introduction</h3>
          <p className="text-gray-600">
            In the world of software development, one important principle that developers often follow is the DRY principle. DRY stands for "Don't Repeat Yourself," and it's all about keeping your codebase clean, efficient, and easy to maintain. In this tutorial, we'll dive into what the DRY principle is and why it's important, along with a practical example in Python.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">What is the DRY Principle?</h3>
          <p className="text-gray-600">
            The DRY principle emphasizes the idea of reducing code duplication by reusing existing code whenever possible. Instead of writing the same code over and over again, DRY encourages you to create reusable components, functions, or modules that can be used in multiple parts of your application. This not only makes your codebase more organized but also reduces the chances of introducing bugs and inconsistencies.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Why is DRY Important?</h3>
          <ul className="list-disc list-inside text-gray-600">
            <li>
              <strong>Maintainability:</strong> When you follow the DRY principle, changes and updates become easier to manage. You only need to make modifications in one place, and those changes will reflect wherever the code is reused.
            </li>
            <li>
              <strong>Readability:</strong> Repeated code can clutter your codebase, making it difficult to understand. By reusing code, you improve readability and make it easier for other developers (and your future self) to understand the logic.
            </li>
            <li>
              <strong>Bug Reduction:</strong> DRY helps in minimizing errors. When you have a single source of truth for a particular logic, there's less chance of inconsistencies that might lead to bugs.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Example: DRY in Python</h3>
          <p className="text-gray-600">
            Let's consider a simple example to illustrate the DRY principle in Python. Imagine you're building a program to calculate the area of various geometric shapes, such as rectangles and circles. Instead of writing separate area calculation functions for each shape, you can create a single function that calculates the area based on the shape's properties.
          </p>
          <pre className="bg-gray-800 p-4 rounded text-white overflow-x-auto">
            <code>
              {`
def calculate_rectangle_area(width, height):
    return width * height

def calculate_circle_area(radius):
    return 3.14159 * radius * radius

# Instead of repeating the calculation logic, we can create a single function
def calculate_area(shape, *args):
    if shape == 'rectangle':
        return calculate_rectangle_area(*args)
    elif shape == 'circle':
        return calculate_circle_area(*args)
    # Add more shapes as needed

# Usage
rectangle_area = calculate_area('rectangle', 4, 5)
circle_area = calculate_area('circle', 3)
              `}
            </code>
          </pre>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Conclusion</h3>
          <p className="text-gray-600">
            The DRY principle is a fundamental concept in software development that promotes code reusability, maintainability, and readability. By avoiding code duplication and creating reusable components, you can create more efficient and robust applications. Remember, following DRY doesn't mean you can't have any repetitive code; it's about finding the right balance and ensuring that repetitive logic is properly abstracted and reused.
          </p>
          <p className="text-gray-600">
            Incorporating DRY into your coding practices will lead to cleaner, more maintainable, and bug-free codebases, making you a more efficient and effective developer.
          </p>
          <p className="text-gray-600">Happy coding!</p>
        </section>
      </div>
    </div>
    </>
  );
};

export default DRYPrinciple;
