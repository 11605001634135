import React from 'react';
import { NavLink } from 'react-router-dom';


function TutorialsNavbar() {
    return (
        <div className="tutorials-navbar">
            <nav className="bg-green-800">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-10">

                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">

                                <NavLink
                                    to="/tutorial"
                                    // activeClassName="text-white bg-gray-900"
                                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                >
                                    Overview
                                </NavLink>
                                <NavLink
                                    to="/tutorial/dry-principle"
                                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                >
                                    DRY Principle
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default TutorialsNavbar;
