import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import logoImage from '../high-quality-upaman-logo.png'
import CalendarWidget from './CalendarWidget.component';

const Header = () => {

const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  
  return (
    <nav className="bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <img src={logoImage} alt="Logo" className="h-8 w-8 text-white mr-1" />
            <span className="text-white font-semibold"><NavLink to="/">Upaman</NavLink></span>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
            
              <NavLink
                // exact
                to="/"
                // activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                <title>Upaman - Your Source for Technology News, General Knowledge, and Fascinating Animal Fun Facts</title>
                News
              </NavLink>
              <NavLink to="/blog" 
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="font-bold">
                Blog
              </NavLink>
              <NavLink to="/gadgets" 
              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              activeClassName="font-bold">
                Gadgets Review
              </NavLink>
              {/* <NavLink
                to="/tutorial"
                // activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Tutorials
              </NavLink> */}
              <NavLink
                to="/animalinfo"
                // activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Animal Fun Facts
              </NavLink>
              
              <NavLink
                to="/about"
                // activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                About
              </NavLink>
              {/* <NavLink
                to="/contact"
                // activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Contact
              </NavLink> */}
              <CalendarWidget/>
            </div>
          </div>
          <div className="md:hidden">
            <button
              type="button"
              className="text-gray-300 hover:text-white focus:outline-none focus:text-white"
              aria-label="Toggle menu"
              onClick={handleToggleMenu}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="md:hidden flex flex-col items-center">
            <div className="mt-4 flex flex-col space-y-2">
              <NavLink
                exact
                to="/"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleToggleMenu}
              >
                Home
              </NavLink>
              <NavLink
                to="/blog"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleToggleMenu}
              >
                Blog
              </NavLink>
              <NavLink
                to="/gadgets"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleToggleMenu}
              >
                Gadgets Review
              </NavLink>
              <NavLink
                to="/animalinfo"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleToggleMenu}
              >
                Animal Fun Facts
              </NavLink>
              {/* <NavLink
                to="/tutorial"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                Tutorial
              </NavLink> */}
              <NavLink
                to="/about"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleToggleMenu}
              >
                About
              </NavLink>
              {/* <NavLink
                to="/contact"
                activeClassName="text-white bg-gray-900"
                className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                onClick={handleToggleMenu}
              >
                Contact
              </NavLink> */}
              
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};


export default Header;