import React, { useState, useEffect } from 'react';
import RouterComponent from './components/RouterComponent.component';
import Loading from './components/Loading.component';
// import './App.css'

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time for demonstration purposes
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
      {isLoading ? <Loading /> :<RouterComponent/>}
    </div>
  );
}

export default App;
