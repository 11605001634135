import React from 'react';
import TutorialsNavbar from './TutorialsNavbar';

function Overview() {
  return (
    <>
    <TutorialsNavbar/>
    
    <div className="bg-gray-100 min-h-screen py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-semibold text-gray-800 mb-4">Overview</h2>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Tutorials overview</h3>
          <p className="text-gray-600">
            In the world of software development, one important principle that developers often follow is the DRY principle. DRY stands for "Don't Repeat Yourself," and it's all about keeping your codebase clean, efficient, and easy to maintain. In this tutorial, we'll dive into what the DRY principle is and why it's important, along with a practical example in Python.
          </p>
        </section>
        </div>
        </div>
        </>
  );
}

export default Overview;
