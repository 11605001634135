// src/components/SamsungS24UltraReview.js

import React from 'react';
import 'tailwindcss/tailwind.css';
import { FaMicrochip, FaMemory, FaCamera, FaBatteryFull } from 'react-icons/fa';

const SamsungS24UltraReview = () => {
  const specs = {
    processor: "Exynos 2200",
    ram: "12GB",
    camera: "200MP main, 50MP 5x telephoto, 12MP ultrawide",
    battery: "5000mAh",
    display: "6.8-inch QHD+ AMOLED",
    storage: "256GB / 512GB / 1TB",
    color: "Titanium Grey, Black, Violet, Yellow"
  };

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded-lg">
      <div className="mb-6 text-center">
        <h1 className="text-3xl font-bold mb-2">Samsung Galaxy S24 Ultra</h1>
        <img src={`${process.env.PUBLIC_URL}/assets/samsung.jpg`} alt="Samsung Galaxy S24 Ultra" className="mx-auto"/>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="text-2xl font-bold mb-4">Specifications</h2>
          <ul>
            <li className="mb-2"><FaMicrochip className="inline-block mr-2" /> <strong>Processor:</strong> {specs.processor}</li>
            <li className="mb-2"><FaMemory className="inline-block mr-2" /> <strong>RAM:</strong> {specs.ram}</li>
            <li className="mb-2"><FaCamera className="inline-block mr-2" /> <strong>Camera:</strong> {specs.camera}</li>
            <li className="mb-2"><FaBatteryFull className="inline-block mr-2" /> <strong>Battery:</strong> {specs.battery}</li>
            <li className="mb-2"><strong>Display:</strong> {specs.display}</li>
            <li className="mb-2"><strong>Storage:</strong> {specs.storage}</li>
            <li className="mb-2"><strong>Available Colors:</strong> {specs.color}</li>
          </ul>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Review Summary</h2>
          <p>The Samsung Galaxy S24 Ultra continues the legacy of its predecessors with significant improvements. The camera setup now includes a 50MP 5x telephoto lens, enhancing low-light performance and overall image fidelity. The phone boasts a robust build with Gorilla Glass Victus 2 and a matte finish that is both grippy and resistant to fingerprints.</p>
          <p>Performance-wise, the Exynos 2200 processor, combined with 12GB of RAM, ensures smooth operation even for the most demanding tasks. The 5000mAh battery provides ample power for a full day’s use, even with heavy usage.</p>
          <p>Additionally, the S Pen remains a valuable feature for those who enjoy note-taking or sketching on the go. With its IP68 rating, the S24 Ultra is well-protected against dust and water, making it a durable option for various environments.</p>
        </div>
      </div>
    </div>
  );
};

export default SamsungS24UltraReview;
