import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './UpcomingEvents.css'; // Import your custom CSS for additional styling

const Events = () => {
    const events = [
        {
            title: "Apple Event",
            description: "Join us for the latest Apple announcements.",
            imgSrc: "assets/default-events-image.jpg",
            link: "https://www.apple.com/apple-events/"
            
        },
        {
            title: "Google I/O",
            description: "Explore the latest in technology at Google I/O.",
            imgSrc: "assets/default-events-image.jpg",
            link: "https://io.google/2024/"
        },
        // {
        //     title: "Apple Event",
        //     description: "Explore the latest innovations from Apple.",
        //     imgSrc: "https://devimages-cdn.apple.com/wwdc-services/articles/images/AFB3A2B1-7463-4BC2-A7DA-27BAF1AA2636/2048.jpeg",
        //     link: "https://www.apple.com/apple-events/"
        // },
        // {
        //     title: "Google I/O",
        //     description: "Join the annual developer conference from Google.",
        //     imgSrc: "https://link-to-google-io-image.jpg",
        //     link: "https://events.google.com/io/"
        // },
        // {
        //     title: "Amazon Prime Day",
        //     description: "Grab amazing deals on Amazon's annual sale.",
        //     imgSrc: "https://link-to-amazon-event-image.jpg",
        //     link: "https://www.amazon.com/primeday"
        // }
        // Add more events here...
    ];

    return (
        <div className="carousel-wrapper my-4">
            <Carousel
                showThumbs={false}
                showStatus={true} // Keep this true to show page count
                showIndicators={true}
                autoPlay={true}
                infiniteLoop={true}
                showArrows={true}
                dynamicHeight={false}
                interval={5000}
            >
                {events.map((event, index) => (
                    <div key={index} className="relative event-card">
                        <a href={event.link} target="_blank" rel="noopener noreferrer">
                            <img src={event.imgSrc} alt={event.title} className="rounded-md" />
                            <div className="absolute bottom-0 bg-black bg-opacity-50 text-white w-full p-4">
                                <h4 className="text-lg font-bold">{event.title}</h4>
                                <p className="text-sm">{event.description}</p>
                            </div>
                        </a>
                    </div>
                ))}
            </Carousel>
        </div>
    );

};

export default Events;
