// AnimalInfo.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {elephantFunFacts,
    lionFacts,
    tigerFacts,
    cheetahFacts,
    kangarooFacts,
    eagleFacts,
    dogsFacts} from './data.js'

const animalData = [
  {
    id: 1,
    name: 'Elephant',
    image: '/assets/elephant.jpg',
    description: 'Majestic, intelligent herbivores symbolizing strength and wisdom in nature.',
    points: elephantFunFacts,
  },
  {
    id: 2,
    name: 'Lion',
    image: '/assets/lion.jpg',
    description: 'Majestic carnivores of the African savannas, known for their powerful roars and living in social groups called prides.',
    points: lionFacts
  },
  {
    id: 3,
    name: 'Tiger',
    image: '/assets/tiger.jpg',
    description: 'Striped big cats with remarkable strength and stealth, found in various habitats across Asia.',
    points: tigerFacts
  },
  {
    id: 4,
    name: 'Cheetah',
    image: '/assets/cheetah.jpg',
    description: ' The fastest land animals, equipped with incredible speed and sporting distinctive tear lines.',
    points: cheetahFacts
  },
  {
    id: 5,
    name: 'Kangaroo',
    image: '/assets/kangaroo.jpg',
    description: 'Iconic marsupials from Australia, known for their powerful hind legs and hopping abilities.',
    points: kangarooFacts
  },
  {
    id: 6,
    name: 'Eagle',
    image: '/assets/eagle.jpg',
    description: 'Majestic birds of prey, renowned for their exceptional eyesight and soaring flight.',
    points: eagleFacts
  },
  {
    id: 7,
    name: 'Dogs',
    image: '/assets/dog.jpg',
    description: 'Loyal and beloved companions, displaying diverse breeds and roles in human lives.',
    points: dogsFacts
  }
  // Add more animal data as needed
];

const AnimalCard = ({ animal }) => {
  const [currentPoint, setCurrentPoint] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 2000, // Set the rotation speed (in milliseconds)
    autoplay: true, // Enable auto-rotation
    autoplaySpeed: 5000, // Set the delay between slides (in milliseconds)
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, nextSlide) => setCurrentPoint(nextSlide),
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="w-full h-48 overflow-hidden">
        <img
          src={animal.image}
          alt={animal.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="mt-4">
        <h3 className="text-xl font-semibold text-gray-800">{animal.name}</h3>
        <p className="text-gray-600">{animal.description}</p>
      </div>
      <div className="mt-4">
        <Slider {...settings}>
          {animal.points.map((point, index) => (
            <div key={index} className={`text-center ${index === currentPoint ? 'opacity-100' : 'opacity-0'}`}>
              <p className="text-left text-gray-600">{point}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

const AnimalInfo = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const pageCount = Math.ceil(animalData.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const visibleAnimals = animalData.slice(startIndex, startIndex + itemsPerPage);

  return (
    <div className="container mx-auto py-4">
      <h1 className="text-3xl font-bold mb-6">Animal Facts</h1>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {visibleAnimals.map((animal) => (
          <AnimalCard key={animal.id} animal={animal} />
        ))}
      </div>

      <div className="flex items-center justify-center mt-4">
        {Array.from({ length: pageCount }).map((_, index) => (
          <button
            key={index}
            className={`mx-1 px-2 py-1 rounded-lg ${
              currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'
            }`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AnimalInfo;
