import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import Font Awesome icons

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handleClick = (page) => {
        onPageChange(page);
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxPagesToShow = 5;
        let startPage, endPage;

        if (totalPages <= maxPagesToShow) {
            // If total pages are less than or equal to maxPagesToShow
            startPage = 1;
            endPage = totalPages;
        } else {
            // Calculate start and end pages
            startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
            endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

            // Adjust startPage if necessary
            if (endPage - startPage < maxPagesToShow - 1) {
                startPage = Math.max(1, endPage - maxPagesToShow + 1);
            }
        }

        // Populate page numbers
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    return (
        <nav className="flex justify-center mt-4">
            <button 
                onClick={() => handleClick(currentPage - 1)} 
                disabled={currentPage === 1}
                className={`mx-1 px-3 py-1 border rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
            >
                <FaChevronLeft />
            </button>

            {/* <span className="mx-1">{`<`}</span> */}

            {getPageNumbers().map((number) => (
                <button 
                    key={number} 
                    onClick={() => handleClick(number)} 
                    className={`mx-1 px-3 py-1 border rounded ${number === currentPage ? 'bg-blue-500 text-white' : 'bg-gray-100'}`}
                >
                    {number}
                </button>
            ))}

            {/* <span className="mx-1">{`>`}</span> */}

            <button 
                onClick={() => handleClick(currentPage + 1)} 
                disabled={currentPage === totalPages}
                className={`mx-1 px-3 py-1 border rounded ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
            >
                <FaChevronRight />
            </button>
        </nav>
    );
};

export default Pagination;
